import React from "react";
import AlertzLogoText from "./AlertzLogoText";
import { useLocation } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

const Header = ({ onClick }) => {
  const location = useLocation();
  return (
    <>
      {/* header-section start */}
      <header className="header-section header-fixed">
        <div className="overlay">
          <div className="container-fluid">
            <div className="row d-flex header-area">
              <nav className="navbar navbar-expand-lg navbar-light">

                <a className="navbar-brand" href="/">
                  <div className="home-logo">
                    <AlertzLogoText size={30} />
                  </div>
                  <div style={{ marginBottom: '-13px' }}>
                    <span style={{ fontWeight: 400, fontSize: 15 }}>
                      Chargeback And Fraud Prevention
                    </span>
                  </div>

                </a>
                <div className="collapse navbar-collapse justify-content-end"
                  id="navbar-content">
                  <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                    {location.pathname === "/" && (
                      <li className="nav-item text-center" style={{ paddingRight: '30px', paddingTop: '10px', cursor: 'pointer' }} onClick={onClick}>
                        {/* <div class="" style={{ lineHeight: '20px', fontSize:'24px' }} id="blinking-button" onClick={handleShowEnrollNowModal}>Enroll Now</div> */}
                        <div class="" style={{ lineHeight: '20px', fontSize: '24px', fontWeight: '800', color: 'black' }}>Enroll Now</div>
                        <div style={{ fontWeight: 300, fontSize: 18, lineHeight: '20px' }}>no credit card required</div>
                      </li>

                    )}
                    <li className="nav-item text-center" style={{ paddingRight: '55px', paddingTop: '10px', cursor: 'pointer' }}>
                      <a href="https://www.linkedin.com/company/alertz" target="_blank" rel="noreferrer"><FaLinkedin size={30} color="#2196f3"/></a>
                    </li>
                  </ul>

                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* header-section end */}
    </>

  )
}
export default Header