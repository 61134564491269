import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorComponent = () => {
  const [editorData, setEditorData] = useState('');

  const handleChange = (event, editor) => {
    const data = editor.getData();
	console.log(data);
    setEditorData(data);
  };

  return (
    <div>
      <h2>CKEditor Example</h2>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleChange}
      />
    </div>
  );
};

export default CKEditorComponent;
