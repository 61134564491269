import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProgressCircle from "../components/Progress/ProgressCircle";
import { useParams } from "react-router-dom";
import WebsiteServiceInstance from "../services/WebsiteService";
import { formattedDate } from "../utilities/utils";
import 'react-quill/dist/quill.snow.css';

const BlogDetails = () => {
    const params = useParams();
    const [blogDetails, setBlogDetails] = useState({})
    useEffect(() => {
        const getBlogDetails = async () => {
            const res = await WebsiteServiceInstance.getWebBlogDetails(params.blogLink)
            setBlogDetails(res.data);
        }
        getBlogDetails()
    }, [params.blogLink]);
    return (
        <>
            <Header />
            {/* Blog Single In start */}
            <section className="blog-single pt-60">
                <div className="overlay pt-5 pb-1">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <span
                                        className="py-1 px-3"
                                        style={{ backgroundColor: 'rgb(0 51 192 / 10%)', color: '#0033CC', borderRadius: '25px' }}>
                                        Our Blog
                                    </span>
                                    <h4 className="pt-4 pb-2">Resources and insights</h4>
                                    <p>Latest industry news, interviews, technologies and resources</p>
                                </div>
                                <div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-9">

                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="blog-contant mb-60">
                                            <span style={{ fontSize: '40px', lineHeight: '50px' }} >{blogDetails.title}</span>
                                            {/* <span style={{ fontSize: '24px', lineHeight: '50px' }} >{blogDetails.title}</span> */}
                                            <div className="info d-flex">
                                                <div className="item d-flex align-items-start">
                                                    <span className=" cus-bor">{blogDetails.author}</span>
                                                    <span className="">{formattedDate(blogDetails.createdOn)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contant-top-area pt-5">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="blog-contant mt-60">
                                                <div class="ql-snow">
                                                    <div class="ql-editor" data-gramm="false" style={{ position: 'relative', padding: '0px', lineHeight: '50px' }}>
                                                        <div className="blog-text" dangerouslySetInnerHTML={{ __html: blogDetails.content }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card" style={{ borderRadius: '25px' }}>
                                    <div className="card-body">
                                        <div style={{ paddingTop: '138px' }}></div>
                                        <div className="text-center">
                                            <button class="cmn-btn">Enroll now</button>
                                            <span>No credit card required</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-4">
                                    <span className="fs-3">Chargeback news</span>
                                </div>
                                <div className="pt-2 d-flex flex-column">
                                    <span className="">20 May 2020</span>
                                    <a href="/" className="fs-5">Subheading text</a>
                                </div>
                                <div className="pt-2 d-flex flex-column">
                                    <span className="">20 May 2020</span>
                                    <a href="/" className="fs-5">Subheading text</a>
                                </div>
                                <div className="pt-2 d-flex flex-column">
                                    <span className="">20 May 2020</span>
                                    <a href="/" className="fs-5">Subheading text</a>
                                </div>
                                <div className="pt-4">
                                    <span className="fs-3">Blog categories</span>
                                </div>
                                <div className="pt-2 d-flex flex-column">
                                    <a href="/" className="">Merchant education</a>
                                    <a href="/" className="">Merchant education</a>
                                    <a href="/" className="">Merchant education</a>
                                </div>
                                <div className="pt-4">
                                    <div className="d-flex flex-row" style={{width:'100%'}}>
                                        <div className="" style={{width:'65%'}}><span className="fs-3">Recent posts</span></div>
                                        <div className="" style={{width:'35%'}}>
                                            <a href="/" className="fs-3">See all</a>
                                            </div>
                                    </div>
                                    <div className="pt-2 d-flex flex-column">
                                    <a href="/" className="">Heading Text</a>
                                    <a href="/" className="">Heading Text</a>
                                    <a href="/" className="">Heading Text</a>
                                    <a href="/" className="">Heading Text</a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Blog Single In end */}
            <ProgressCircle />
            <Footer />
        </>
    )
}
export default BlogDetails