import React, { useEffect, useState } from "react";
const MobileEnrollNow = ({ onClick }) => {
    const [progress, setProgress] = useState(0);
    const updateProgressCircle = () => {
        const totalHeight = document.body.scrollHeight - window.innerHeight;
        let newProgress = (window.pageYOffset / totalHeight) * 100;
        newProgress = Math.min(newProgress, 100);
        setProgress(newProgress);
      };
    
      useEffect(() => {
        updateProgressCircle();
        window.addEventListener('scroll', updateProgressCircle);
        window.addEventListener('resize', updateProgressCircle);
        return () => {
          window.removeEventListener('scroll', updateProgressCircle);
          window.removeEventListener('resize', updateProgressCircle);
        };
      }, []);
    return (
        <>
        {progress >= 95 ? (<></>
        ):(<>
            <div className="text-center enroll-now-section">
                <button class="cmn-blank-btn mobile-section"
                    onClick={onClick}
                    style={{ display: 'flex !important', flexDirection: 'column', alignItems: 'center' }}>
                    Enroll Now
                    <br />
                    <span className="text-light" style={{ marginTop: '-5px' }}>no credit card required </span>
                </button>
            </div>
            </>)}
        </>
    )
}
export default MobileEnrollNow;