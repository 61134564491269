import React, { useState } from "react";
import { toast } from "react-toastify";
import WebsiteService from "../../services/WebsiteService";
import { Modal } from "react-bootstrap-v5";
import AlertzLogoText from "../AlertzLogoText";
const EarlyAccessNowDesktopModal = ({ isEnrollNowModal, handleCloseEnrollNowModal, isFormSubmited, setIsFormSubmited }) => {
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const [name, setName] = useState("")
    const [nameError, setNameError] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [businessNameError, setBusinessNameError] = useState("")
    const [businessEmail, setBusinessEmail] = useState("")
    const [businessEmailError, setBusinessEmailError] = useState("")
    const [businessURL, setBusinessURL] = useState("")
    const [businessURLError, setBusinessURLError] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState("")
    const [numberOfChargebacks, setNumberOfChargebacks] = useState("")

    const handleNameInput = (e) => {
        setNameError("")
        setName(e.target.value)
    };
    const handleBusinessNameInput = (e) => {
        setBusinessNameError("")
        setBusinessName(e.target.value)
    };
    const handleBusinessEmailInput = (e) => {
        setBusinessEmailError("")
        setBusinessEmail(e.target.value)
    };
    const handleBusinessURLInput = (e) => {
        setBusinessURLError("")
        setBusinessURL(e.target.value)
    };
    const handlePhoneNumberInput = (e) => {
        const inputValue = e.target.value;
        const phoneNumber = inputValue.replace(/\D/g, ''); // Remove non-digit characters
        if (phoneNumber.length <= 10) {
            setPhoneNumberError("");
            setPhoneNumber(phoneNumber);
        }
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email) ? true : false;
    };
    const validatePhone = (phone) => {
        if (phone.length >= 10) {
            return true;
        } else {
            return false;
        }
    };
    const validateForm = () => {
        const isEmailValid = validateEmail(businessEmail);
        const isNameValid = !!name;
        const isBusinessNameValid = !!businessName;
        const isBusinessURLValid = !!businessURL;
        const isPhoneNumberValid = !!phoneNumber;
        const isPhoneNumberValidL = !!validatePhone(phoneNumber);

        setBusinessEmailError(isEmailValid ? "" : "Invalid email address");
        setNameError(isNameValid ? "" : "Name is required");
        setBusinessNameError(isBusinessNameValid ? "" : "Business name is required");
        setBusinessURLError(isBusinessURLValid ? "" : "Business url is required");
        setPhoneNumberError(isPhoneNumberValid ? "" : "Phone number is required");
        setPhoneNumberError(isPhoneNumberValidL ? "" : "Phone number must be 10 digit");


        console.log(isEmailValid);
        return (
            isEmailValid &&
            isNameValid &&
            isBusinessURLValid &&
            isBusinessNameValid &&
            isPhoneNumberValid &&
            isPhoneNumberValidL
        );
    }
    const handleAddData = async () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            // if (!isCaptchaVerified) {
            //     toast.warn("Please complete the reCAPTCHA.");
            //     return false;
            // }
            const fData = new FormData();
            fData.append("name", name);
            fData.append("businessName", businessName);
            fData.append("businessEmail", businessEmail);
            fData.append("phoneNumber", phoneNumber);
            fData.append("numberOfChargebacks", numberOfChargebacks);
            try {
                const res = await WebsiteService.submitGetEarlyAccess(fData);
                if (res.data.error === false) {
                    // toast.success(res.data.message);
                    setIsFormSubmited(true)
                    setName("")
                    setBusinessName("")
                    setBusinessEmail("")
                    setPhoneNumber("")
                    setBusinessURL("")
                    setNumberOfChargebacks("")
                } else {
                    toast.error(res.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            // toast.warn("Please Fill All Required Fields.");
            handleOpenErrorModal()
        }
    }

    const handleNumberOfChargebacksInput = (e) => {
        setNumberOfChargebacks(e.target.value)
    };
    const handleOpenErrorModal = () => {
        setIsErrorModalOpen(true);
    };
    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);

    };
    return (
        <>
            <Modal
                show={isEnrollNowModal}
                onHide={handleCloseEnrollNowModal}
                centered backdrop="static"
                keyboard={false}
                size="xl">
                <Modal.Body>
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="relative w-full max-h-full">
                            {/* Modal content */}
                            <div className="relative bg-white rounded-lg">
                                {/* Modal header */}
                                <div className="flex items-center justify-between p-1 rounded-t dark:border-gray-600">
                                </div>
                                {/* Modal body */}
                                {isFormSubmited ? (<>
                                    <div className="space-y-4 justify-content-center align-items-center d-flex text-center" style={{ minHeight: '426px' }}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5>Thank you for your interest in <AlertzLogoText size={24}/>
                                                </h5>
                                                <p className="pt-4">
                                                    <b>Our experts will connect with you soon to obtain additional details to start <AlertzLogoText size={15}/> service.</b>
                                                </p>
                                                <div className="text-start">
                                                    <span>We look forward to:</span>
                                                    <ul className="list">
                                                        <li
                                                            className="list-item d-flex align-items-center"
                                                            style={{ paddingTop: 10 }}
                                                        >
                                                            <span className="check d-flex align-items-center justify-content-center">
                                                                <img src="assets/img/icon/check.png" alt="icon" />
                                                            </span>
                                                            <span>Reducing your chargebacks.</span>
                                                        </li>
                                                        <li
                                                            className="list-item d-flex align-items-center "
                                                            style={{ paddingTop: 10 }}
                                                        >
                                                            <span className="check d-flex align-items-center justify-content-center">
                                                                <img src="assets/img/icon/check.png" alt="icon" />
                                                            </span>
                                                            <span>
                                                                Maximizing revenue retention
                                                            </span>
                                                        </li>
                                                        <li
                                                            className="list-item d-flex align-items-center "
                                                            style={{ paddingTop: 10 }}
                                                        >
                                                            <span className="check d-flex align-items-center justify-content-center">
                                                                <img src="assets/img/icon/check.png" alt="icon" />
                                                            </span>
                                                            <span>Elevating customer experience</span>
                                                        </li>
                                                        <li
                                                            className="list-item d-flex align-items-center "
                                                            style={{ paddingTop: 10 }}
                                                        >
                                                            <span className="check d-flex align-items-center justify-content-center">
                                                                <img src="assets/img/icon/check.png" alt="icon" />
                                                            </span>
                                                            <span>Delivering Chargeback Management solutions tailored to your needs</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <button onClick={handleCloseEnrollNowModal} className="cmn-btn-cancel mt-4" style={{ width: '100px' }}>Close</button>
                                            </div>
                                        </div>
                                    </div>

                                </>) : (
                                    <>
                                        <div className="space-y-4" style={{ minHeight: '590px' }}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-content">
                                                        <div className="section-header text-center mb-3">
                                                            <h4 className="title">Get Early Access Now!</h4>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="fname">Name</label>
                                                                    <input type="text"
                                                                        value={name}
                                                                        onChange={handleNameInput} />
                                                                    <div className="text-end">
                                                                        <p className="text-[13px] text-danger">{nameError}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="lname">Business Name</label>
                                                                    <input
                                                                        type="text"
                                                                        value={businessName}
                                                                        onChange={handleBusinessNameInput} />
                                                                    <div className="text-end">
                                                                        <p className="text-[13px] text-danger">{businessNameError}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="email">Business Email</label>
                                                                    <input
                                                                        type="text"
                                                                        value={businessEmail}
                                                                        onChange={handleBusinessEmailInput} />
                                                                    <div className="text-end">
                                                                        <p className="text-[13px] text-danger">{businessEmailError}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="lname">Business URL</label>
                                                                    <input
                                                                        type="text"
                                                                        value={businessURL}
                                                                        onChange={handleBusinessURLInput} />
                                                                    <div className="text-end">
                                                                        <p className="text-[13px] text-danger">{businessURLError}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="email">Phone</label>
                                                                    <input
                                                                        type="text"
                                                                        value={phoneNumber}
                                                                        onChange={handlePhoneNumberInput} />
                                                                    <div className="text-end">
                                                                        <p className="text-[13px] text-danger">{phoneNumberError}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input">
                                                                    <label htmlFor="referral">
                                                                        Average monthly chargebacks
                                                                    </label>
                                                                    <select
                                                                        className="form-select"
                                                                        aria-label="Default select"
                                                                        onChange={handleNumberOfChargebacksInput} value={numberOfChargebacks}
                                                                    >
                                                                        <option value="0_100">0 -100 chargebacks</option>
                                                                        <option value="100_500">100 - 500 chargebacks</option>
                                                                        <option value={3}>&gt; 500 chargebacks</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">
                                                                <div className="single-input" style={{ paddingTop: 10 }}>
                                                                    <p>
                                                                        By clicking submit, you agree to alertZ Terms of Use, Privacy Policy, E-sign
                                                                        {" "}<span>communication Authorization.</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1"></div>
                                                            <div className="col-10">

                                                                <div className="text-end pt-3">
                                                                    <button onClick={handleCloseEnrollNowModal} className="cmn-btn-cancel" style={{ width: '100px' }}>Close</button>
                                                                    <button onClick={handleAddData} className="cmn-btn ms-3">Submit Now</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal style={{ background: '#989898bd' }} size="md" show={isErrorModalOpen} onHide={handleCloseErrorModal} centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="relative w-full max-h-full">
                            {/* Modal content */}
                            <div className="relative bg-white rounded-lg">
                                {/* Modal header */}
                                <div className="flex items-center justify-between p-1 rounded-t dark:border-gray-600">
                                </div>
                                {/* Modal body */}
                                <div className=" md:p-5 space-y-4">
                                    <p className="text-[14px] leading-[20px] font-normal text-[#667085] text-center">
                                        Please Fill All Required Fields.
                                    </p>
                                </div>
                                <div className="text-center pt-4">
                                    <button onClick={handleCloseErrorModal} className="btn btn-primary" style={{ width: '100px' }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default EarlyAccessNowDesktopModal