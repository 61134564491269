import React, { useEffect, useState } from 'react';

function ProgressCircle() {
  const [progress, setProgress] = useState(0);

  const updateProgressCircle = () => {
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    let newProgress = (window.pageYOffset / totalHeight) * 100;
    newProgress = Math.min(newProgress, 100);
    setProgress(newProgress);
  };

  useEffect(() => {
    updateProgressCircle();
    window.addEventListener('scroll', updateProgressCircle);
    window.addEventListener('resize', updateProgressCircle);
    return () => {
      window.removeEventListener('scroll', updateProgressCircle);
      window.removeEventListener('resize', updateProgressCircle);
    };
  }, []);

  return (
    <div className='progress-circle-main'>
      <svg className="progress-circle" viewBox="0 0 100 100" width="50" height="50">
        <circle className="progress-background" cx="50" cy="50" r="45" fill="none" stroke="#fff" strokeWidth="5"></circle>
        <circle
          className="progress-circle-bar"
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#f97315"
          strokeWidth="5"
          strokeDasharray="283"
          strokeDashoffset={283 - (progress / 100) * 283}
        ></circle>
        <text x="50" y="50" textAnchor="middle" dominantBaseline="central" fill="#f97315" fontSize="30">
          {Math.round(progress)}%
        </text>
      </svg>
    </div>
  );
}

export default ProgressCircle;
