import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import HowAlertzGameChanger from "./pages/HowAlertzGameChanger";
import ProgressBar from "./components/Progress/ProgressBar";
import VisaCompellingEvidence from "./pages/VisaCompellingEvidence";
import Editor from "./pages/CKEditorComponent";
import HomeCopy from "./pages/HomeCopy";
import BlogDetails from "./pages/BlogDetails";
import './QlEditor.css';

function App() {
  return (
    <div className="App">
      <Router>
      <ProgressBar />   
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/HomeCopy" element={<HomeCopy />}></Route>
          <Route exact path="/Editor" element={<Editor />}></Route>
          <Route
            exact
            path="/blog/:blogLink"
            element={<BlogDetails />}
          />
          <Route exact path="/How-alertz-is-a-game-changer" element={<HowAlertzGameChanger />}></Route>
          <Route exact path="/Visa-compelling-evidence-3-0" element={<VisaCompellingEvidence />}></Route>
        </Routes>
<ToastContainer />
      </Router>
    </div>
  );
}

export default App;
