import React from "react"
const AlertzLogoText = ({size}) => {

    return (
        <>
            <span style={{ fontSize: size, fontWeight: 600, color: "black" }}>
                alert
                <span
                    style={{
                        fontSize: size,
                        fontWeight: 600,
                        color: "rgb(255 102 0)"
                    }}
                >
                    <i>Z</i>
                </span>
            </span>
        </>
    )
}
export default AlertzLogoText