import React from "react";
import { FaLinkedin } from "react-icons/fa";
const Footer = () => {
    return (
        <>
            {/* Footer Area Start */}
            <div className="footer-section pt-60">
                <div className="container" style={{ paddingTop: 20 }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom">
                                <div className="left">
                                    <p>
                                        {" "}
                                        Copyright © <a href="index-2.html">alertz.info 2024</a> |
                                        Designed by <a href="https://vrezolve.com/"> vRezolve</a>
                                    </p>
                                </div>
                                <div class="right">
                                    {/* <a href="privacy-policy.html" class="cus-bor">Privacy </a> */}
                                    <a href="https://www.linkedin.com/company/alertz" target="_blank" rel="noreferrer"><FaLinkedin size={27} color="#2196f3"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="img-area">
                    <img
                        src="assets/images/footer-Illu-left.png"
                        className="left"
                        alt="Images"
                    />
                    <img
                        src="assets/images/footer-Illu-right.png"
                        className="right"
                        alt="Images"
                    />
                </div>
            </div>
            {/* Footer Area End */}
        </>
    )
}
export default Footer