import React, { useEffect, useState } from 'react';

function ProgressBar() {
  const [progress, setProgress] = useState(0);

  const updateProgressBar = () => {
    const totalHeight = document.body.scrollHeight - window.innerHeight;
    const newProgress = (window.pageYOffset / totalHeight) * 100;
    setProgress(newProgress);
  };

  useEffect(() => {
    updateProgressBar();
    window.addEventListener('scroll', updateProgressBar);
    window.addEventListener('resize', updateProgressBar);
    return () => {
      window.removeEventListener('scroll', updateProgressBar);
      window.removeEventListener('resize', updateProgressBar);
    };
  }, []);

  return <div className="progress-bar" 
  style={{ width: `${progress}%`, position: 'fixed', top: 0, left: 0, zIndex: 9999, backgroundColor: '#FF6600', height: '5px' }}></div>;
}

export default ProgressBar;
