import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import MobileEnrollNow from "../components/MobileEnrollNow/MobileEnrollNow";
import ProgressCircle from "../components/Progress/ProgressCircle";
import EarlyAccessNowMobileModal from "../components/Modals/EarlyAccessNowMobileModal";
import EarlyAccessNowDesktopModal from "../components/Modals/EarlyAccessNowDesktopModal";
import Header from "../components/Header";
import WebsiteServiceInstance from "../services/WebsiteService";
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { MdBrightness1 } from "react-icons/md";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { formattedDate } from "../utilities/utils";

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
  const [blogList, setBlogList] = useState([])
  const [mobilBlogList, setMobileBlogList] = useState([])
  const [currentSlide, setCurrentSlide] = React.useState(0);


  const getRecentPostList = async() =>{
    const res = await WebsiteServiceInstance.getRecentPostList();
    console.log(res.data);
  }
  useEffect(() => {
    getRecentPostList()
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const listener = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener('change', listener);
    return () => mediaQuery.removeEventListener('change', listener);
  }, []);

  const [isEnrollNowModal, setIsEnrollNowModal] = useState(false);
  const [isEnrollNowMobileModal, setIsEnrollNowMobileModal] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false)

  const handleCloseEnrollNowModal = () => {
    setIsEnrollNowModal(false);
    setIsFormSubmited(false)
  };

  const handleShowEnrollNowModal = () => {
    setIsEnrollNowModal(true);
  };
  const handleCloseEnrollNowMobileModal = () => {
    setIsEnrollNowMobileModal(false);
    setIsFormSubmited(false)
  };

  const handleShowEnrollNowMobileModal = () => {
    setIsEnrollNowMobileModal(true);
  };

  const getBlogList = async () => {
    const res = await WebsiteServiceInstance.getWebBlogList();
    setMobileBlogList(res.data);

    // Group the blog data into pairs
    const groupedBlogList = res.data.reduce((acc, blog, index) => {
      if (index % 3 === 0) {
        acc.push([blog]);
      } else {
        acc[acc.length - 1].push(blog);
      }
      return acc;
    }, []);

    setBlogList(groupedBlogList);
  }

  const CustomPrevArrow = (props) => (
    <button type="button" {...props} data-role="none" className="slick-arrow slick-prev" style={{ display: 'block' }}>
      <RiArrowLeftSLine size={19} style={{ marginLeft: '-4px', marginTop: '-4px' }} />
    </button>
  );

  const CustomNextArrow = (props) => (
    <button type="button" {...props} data-role="none" className="slick-arrow slick-next" style={{ display: 'block' }}>
      <RiArrowRightSLine size={19} style={{ marginTop: '-4px' }} />
    </button>
  );


  const handleOnSliderPageChange = (i) => {
    console.log("setCurrentSlide", i);
    setCurrentSlide(i);
  }
  const settings = {
    infinite: true,
    autoplay: false,
    focusOnSelect: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    dots: false,
    // dotsClass: 'section-dots',
    customPaging: function (i) {
      return (
        <MdBrightness1
          key={i}
          onClick={() => {
            handleOnSliderPageChange(i)
          }}
          style={{
            fontSize: 20,
            cursor: 'pointer',
            color: i === currentSlide ? 'rgb(255 102 0)' : '#bdc2d1',
          }}
        />
      );
    },
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  useEffect(() => {
    getBlogList()
  }, []);
  return (
    <>
      {/* handleShowEnrollNowModal */}
      <Header onClick={handleShowEnrollNowModal} />
      {/* Hero section start */}
      <section className="banner-section">
        <div className="overlay">
          <div className="banner-content d-flex align-items-center">
            <div className="container-fluid">
              <div className="row justify-content-start">
                <div className="col-lg-7 col-md-10">
                  <div className="main-content pt-60">
                    <div className="top-area section-text justify-content-center">

                      <h3 className="title hero-heading">
                        Chargebacks And Fraud Prevention For <br /> E-commerce Merchants
                      </h3>
                      <br />
                      <div>
                        <span style={{ fontSize: 21, lineHeight: '30px' }}>
                          <span style={{ color: "black", fontSize: 26 }}>alert</span>
                          <span style={{ color: "rgb(255 102 0)", fontSize: 26 }}>
                            <i>Z</i>
                          </span>{" "}
                          is a <b style={{ fontSize: 24 }}>subscription</b> based service that instantly notifies you of <b style={{ fontSize: 24, color: '#ff6600' }}>confirmed chargebacks</b> and <b style={{ fontSize: 24, color: '#ff6600' }}>fraudulent transactions</b>
                        </span>
                      </div>
                      <ul className="list">
                        <li
                          className="list-item d-flex align-items-center"
                          style={{ paddingTop: 10 }}
                        >
                          <span className="check d-flex align-items-center justify-content-center">
                            <img src="assets/img/icon/check.png" alt="icon" />
                          </span>
                          <span>Prevent Fraud Losses.</span>
                        </li>
                        <li
                          className="list-item d-flex align-items-center "
                          style={{ paddingTop: 10 }}
                        >
                          <span className="check d-flex align-items-center justify-content-center">
                            <img src="assets/img/icon/check.png" alt="icon" />
                          </span>
                          <span>
                            Instant Notifications for Confirmed Fraud Transactions
                          </span>
                        </li>
                        <li
                          className="list-item d-flex align-items-center "
                          style={{ paddingTop: 10 }}
                        >
                          <span className="check d-flex align-items-center justify-content-center">
                            <img src="assets/img/icon/check.png" alt="icon" />
                          </span>
                          <span>Prevents Chargebacks After Shipment</span>
                        </li>
                        <li
                          className="list-item d-flex align-items-center "
                          style={{ paddingTop: 10 }}
                        >
                          <span className="check d-flex align-items-center justify-content-center">
                            <img src="assets/img/icon/check.png" alt="icon" />
                          </span>
                          <span>Proactive Fraud Prevention</span>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="bottom-area">
                      <button onClick={scrollToGetEarlyAccessNow} className="cmn-home-btn">
                        Get Early Access
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero section end */}

      {/* service desktop section start */}
      <section className="feature-card-section">
        <div className="overlay pb-60 pt-3">
          <div className="container-fluid wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-header text-center mb-3">
                  <h3 className="title" style={{ fontSize: '36px', lineHeight: '36px' }}>Features & Benefits</h3>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="col-lg-12 p-0">
                <ul className="nav nav-tabs mobile-section"
                  role="tablist"
                  style={{ width: "100%", justifyContent: "center" }}>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "33%", fontSize: '16px' }}
                  >
                    <button
                      className="nav-link active"
                      id="credit-tab"
                      data-bs-toggle="tab"
                      style={{ width: "100%", padding: '10px' }}
                      data-bs-target="#credit"
                      type="button"
                      role="tab"
                      aria-controls="credit"
                      aria-selected="true"
                    >
                      Real - Time <br /> Fraud Alerts
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "33%", fontSize: '16px' }}
                  >
                    <button
                      className="nav-link"
                      id="debit-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#debit"
                      style={{ width: "100%", padding: '10px' }}
                      type="button"
                      role="tab"
                      aria-controls="debit"
                      aria-selected="false"
                    >
                      Chargeback <br /> Alerts
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "34%", fontSize: '16px' }}
                  >
                    <button
                      className="nav-link"
                      id="debit-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#debit1"
                      style={{ width: "100%", padding: '10px' }}
                      type="button"
                      role="tab"
                      aria-controls="debit1"
                      aria-selected="false"
                    >
                      Friendly Fraud <br /> Prevention
                    </button>
                  </li>

                </ul>
                <ul className="nav nav-tabs desktop-section"
                  role="tablist"
                  style={{ width: "100%", justifyContent: "center" }}>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "28%" }}
                  >
                    <button
                      className="nav-link active"
                      id="credit-tab"
                      data-bs-toggle="tab"
                      style={{ width: "100%" }}
                      data-bs-target="#credit"
                      type="button"
                      role="tab"
                      aria-controls="credit"
                      aria-selected="true"
                    >
                      Real - Time Fraud Alerts
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "22%" }}
                  >
                    <button
                      className="nav-link"
                      id="debit-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#debit"
                      style={{ width: "100%" }}
                      type="button"
                      role="tab"
                      aria-controls="debit"
                      aria-selected="false"
                    >
                      Chargeback Alerts
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "28%" }}
                  >
                    <button
                      className="nav-link"
                      id="debit-tab1"
                      data-bs-toggle="tab"
                      data-bs-target="#debit1"
                      style={{ width: "100%" }}
                      type="button"
                      role="tab"
                      aria-controls="debit1"
                      aria-selected="false"
                    >
                      Friendly Fraud Prevention
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ width: "22%" }}
                  >
                    <button
                      className="nav-link"
                      id="debit-tab2"
                      data-bs-toggle="tab"
                      data-bs-target="#debit2"
                      style={{ width: "100%" }}
                      type="button"
                      role="tab"
                      aria-controls="debit2"
                      disabled={true}
                      aria-selected="false"
                    >
                      Chargeback Processing <br />
                      <div
                        style={{
                          fontSize: 12,
                          marginBottom: "-60px",
                          marginTop: "-10px",
                          color: "#ff6600"
                        }}
                      >
                        Coming Soon !!
                      </div>
                    </button>
                  </li>
                </ul>
                <div className="tab-content px-2">
                  {/* Real - Time Fraud Alerts */}
                  <div
                    className="tab-pane fade show active"
                    id="credit"
                    role="tabpanel"
                    aria-labelledby="credit-tab"  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="top-section">
                          <h5 className="sub-title pt-3">AlertZ stops fraud before it costs you money!</h5>
                          <h3 className="title fs-3" style={{ lineHeight: '36px' }}>Get Real-time confirmed fraud alerts even before you ship or provide any service !</h3>
                          <p>
                            <span style={{ color: "black", fontWeight: 800 }}>
                              alert
                            </span>
                            <span style={{ color: "rgb(255 102 0)" }}>
                              <i>Z</i>
                            </span>{" "}
                            connects you directly with the credit card company and you get notified of a <b style={{ fontSize: '18px' }}>confirmed fraud transaction</b> in
                            real-time even before you ship the order or provide the service,
                            allowing the merchant to take an action to <b style={{ fontSize: '18px' }}>prevent expensive chargeback </b>
                            that usually happens after order is shipped.
                          </p>
                          <ul className="list pt-3">
                            <li className="list-item d-flex align-items-center pt-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Prevent Chargebacks & associated fees.</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Avoid Fraud Losses</span>
                            </li>
                            <li className="list-item d-flex align-items-center ">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Improve Chargeback Ratio</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Save reputation</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 text-end features-and-benefits-image-section"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignSelf: "center"
                        }}
                      >
                        <div className="img-area">
                          <img
                            src="assets/img/RealTimeFraudAlerts.png"
                            alt="RealTimeFraudAlerts"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Chargeback Alerts */}
                  <div
                    className="tab-pane fade"
                    id="debit"
                    role="tabpanel"
                    aria-labelledby="debit-tab"
                  >
                    <div className="row">

                      <div className="col-lg-6">
                        <div className="top-section">
                          {/* <span
                            className="head-icon d-flex justify-content-center align-items-center"
                            style={{ backgroundColor: "white" }}
                          >
                            <img src="assets/img/icons/chargeback.png" alt="icon" />
                          </span> */}
                          <h5 className="sub-title pt-3">
                            Up to 40% of chargebacks are preventable
                          </h5>
                          <h3 className="title fs-3" style={{ lineHeight: '36px' }}>Stop chargebacks before they start!</h3>
                          <p>
                            <span style={{ color: "black", fontWeight: 800 }}>
                              alert
                            </span>
                            <span style={{ color: "rgb(255 102 0)" }}>
                              <i>Z</i>
                            </span>{" "}
                            gives you advance warning of potential incoming chargebacks.
                            This lets you address concerns directly with your customers, preventing the hassle
                            of chargebacks and associated fees.
                            Resolve issues quickly, keep customers happy, and protect your profits with
                            <span style={{ color: "black", fontWeight: 800 }}>
                              &nbsp;alert
                            </span>
                            <span style={{ color: "rgb(255 102 0)" }}>
                              <i>Z</i>
                            </span>.
                          </p>
                          <ul className="list pt-3">
                            <li className="list-item d-flex align-items-center pt-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Direct Issue Resolution</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Enhanced Ratio Management</span>
                            </li>
                            <li className="list-item d-flex align-items-center ">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Customer Experience Improvement</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Proactive Dispute Prevention</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 text-start cus-ord features-and-benefits-image-section"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignSelf: "center"
                        }}
                      >
                        <div className="img-area">
                          <img src="assets/img/alertz-pc-image.png" alt="alertz-pc-image2"
                            style={{ height: '458px' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Friendly Fraud Prevention */}
                  <div
                    className="tab-pane fade"
                    id="debit1"
                    role="tabpanel"
                    aria-labelledby="debit-tab1"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="top-section">
                          {/* <span
                            className="head-icon d-flex justify-content-center align-items-center"
                            style={{ backgroundColor: "white" }}
                          >
                            <img src="assets/img/icons/prevention.png" alt="icon" />
                          </span> */}
                          <h5 className="sub-title pt-3">
                            Averting friendly fraud risks
                          </h5>
                          <h3 className="title fs-3" style={{ lineHeight: '36px' }}>Prevent friendly fraud, turn disputes into happy customers</h3>
                          <p>
                            <span style={{ color: "black", fontWeight: 800 }}>
                              alert
                            </span>
                            <span style={{ color: "rgb(255 102 0)" }}>
                              <i>Z</i>
                            </span>{" "}
                            empowers you to provide cardholders with clear transaction details when
                            they dispute a transaction, helping them quickly understand and resolve any issues.
                            This transparency reduces chargebacks and saves you time by making dispute resolution effortless.
                          </p>
                          <ul className="list pt-3">
                            <li className="list-item d-flex align-items-center pt-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Thorough Transaction Details</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Transparency to Deter Chargebacks</span>
                            </li>
                            <li className="list-item d-flex align-items-center ">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Effortless Information Generation</span>
                            </li>
                            <li className="list-item d-flex align-items-center py-2">
                              <span className="check d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/icon/check.png"
                                  alt="icon"
                                />
                              </span>
                              <span>Rapid Dispute Resolution</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 text-start cus-ord features-and-benefits-image-section"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignSelf: "center"
                        }}
                      >
                        <div className="img-area">
                          <img
                            src="assets/img/FriendlyFraudPrevention.png"
                            alt="FriendlyFraudPrevention"
                            style={{ height: '458px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="debit2"
                    role="tabpanel"
                    aria-labelledby="debit-tab2"
                  >
                    <div className="text-center">
                      <h4>Coming-Soon</h4>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* service desktop section end */}

      {/* Why Alertz? In start */}
      <section className="more-feature" style={{ position: 'inherit' }}>
        <div className="overlay pt-4 pb-60">
          <div className="container wow fadeInUp">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="section-header text-center mb-4">
                  <h2 className="title" style={{ fontSize: '36px' }}>
                    Why <span style={{ color: "black", fontWeight: 800 }}>
                      alert
                    </span>
                    <span style={{ color: "rgb(255 102 0)" }}>
                      <i>Z</i>
                    </span> ?
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="feature-carousel">
                <div className="single-item">
                  <div className="single">
                    <div className="top-content text-center slider-top-contant">
                      <div class="d-flex justify-content-center">
                        <img src="assets/img/icon/reduce-chargeback-rates.png" width={80} alt="card-feature-1" class="max-un pb-4" />
                      </div>
                      <h5>Reduce Chargeback Rates</h5>
                      <p className="xl pt-4">
                        <span style={{ color: "black", fontWeight: 800 }}>
                          alert
                        </span>
                        <span style={{ color: "rgb(255 102 0)" }}>
                          <i>Z</i>
                        </span> helps businesses catch fraudulent charges before they become
                        expensive disputes.  With real-time notifications and fraud detection,
                        businesses can proactively resolve issues, saving money and reducing
                        the administrative hassle of responding to chargebacks.
                      </p>
                    </div>

                  </div>
                </div>
                <div className="single-item">
                  <div className="single">
                    <div className="top-content text-center slider-top-contant">
                      <div class="d-flex justify-content-center">
                        <img src="assets/img/icon/streamline-dispute-resolution.png" width={80} alt="card-feature-2" class="max-un pb-4" />
                      </div>
                      <h5>Streamline Dispute Resolution</h5>
                      <p className="xl pt-4">
                        <span style={{ color: "black", fontWeight: 800 }}>
                          alert
                        </span>
                        <span style={{ color: "rgb(255 102 0)" }}>
                          <i>Z</i>
                        </span> automates the dispute resolution process, eliminating the
                        need for manual data entry and paperwork. <br /> This saves merchants
                        time and resources, allowing them to focus on core business activities.
                      </p>
                    </div>

                  </div>
                </div>
                <div className="single-item">
                  <div className="single">
                    <div className="top-content text-center slider-top-contant">
                      <div class="d-flex justify-content-center">
                        <img src="assets/img/icon/improve-customer-satisfaction.png" width={80} alt="improve-customer-satisfaction" class="max-un pb-4" />
                      </div>
                      <h5>Improve Customer Satisfaction</h5>
                      <p className="xl pt-4">
                        By resolving disputes quickly and efficiently, AlertZ helps businesses
                        maintain positive customer relationships. This is because timely
                        resolution prevents frustration and avoids
                        situations where customers feel ignored or unheard.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-item">
                  <div className="single">
                    <div className="top-content text-center slider-top-contant">
                      <div class="d-flex justify-content-center">
                        <img src="assets/img/icon/protectyour-bottom-line-icon-second-option.svg" width={60} alt="Protect-Your-Bottom-Line" class="max-un pb-5" />
                      </div>
                      <h5>Protect Your Bottom Line</h5>
                      <p className="xl pt-4">
                        <span style={{ color: "black", fontWeight: 800 }}>
                          alert
                        </span>
                        <span style={{ color: "rgb(255 102 0)" }}>
                          <i>Z</i>
                        </span> comprehensive chargeback management solution safeguards
                        your revenue from fraudulent activity. By mitigating fraudulent
                        transactions and streamlining dispute resolution, AlertZ helps
                        businesses  protect their profits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Alertz? In end */}

      {/* Who Can Benefit from AlertZ In start */}
      <section className="account-feature features" style={{ background: 'white' }}>
        <div className="overlay pt-3 pb-60">
          <div className="container-fluid wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="section-header text-center">
                  {/* <h5 className="sub-title">
                Boost your savings with the right credit card
                </h5> */}
                  <h2 className="title" style={{ fontSize: '36px' }}>
                    Who Can Benefit from <span style={{ color: "black", fontWeight: 800 }}>
                      alert
                    </span>
                    <span style={{ color: "rgb(255 102 0)" }}>
                      <i>Z</i>
                    </span> ?
                  </h2>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-md-3">
                <div className="single-box">
                  <div className="icon-box">
                    <img src="assets/img/icon/high-chargeback-risk-merchants.png" alt="icon" />
                  </div>
                  <h5>High Chargeback Risk Merchants</h5>
                  <p>
                    <span style={{ color: "black", fontWeight: 800 }}>
                      alert
                    </span>
                    <span style={{ color: "rgb(255 102 0)" }}>
                      <i>Z</i>
                    </span> helps you to reduce your chargeback to transaction ratios by providing fraud and chargeback alerts so that your business remains risk free and compliant.
                  </p>
                </div>
              </div>
              <div className="col-md-3" >
                <div className="single-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/icon/established-e-commerce-stores.png"
                      alt="icon"
                    />
                  </div>
                  <h5>Established E-commerce Stores</h5>
                  <p>
                    Enhance your store's security and streamline dispute resolution with AlertZ's advanced features. Safeguard your reputation and customer trust with our robust fraud prevention tools
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="single-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/icon/growing-businesses.png"
                      alt="icon"
                    />
                  </div>
                  <h5>Growing <br /> Businesses</h5>
                  <p>
                    Protect your growing business with AlertZ's affordable subscription plan. Scale confidently while shielding your profits from potential fraud risks
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="single-box">
                  <div className="icon-box">
                    <img
                      src="assets/img/icon/subscription-businesses.png"
                      alt="icon"
                    />
                  </div>
                  <h5>Subscription <br /> Businesses</h5>
                  <p>
                    Ensure the integrity of your recurring revenue streams with AlertZ's comprehensive fraud protection. Keep your subscription-based business safe from fraudulent activity and maintain customer loyalty.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* Who Can Benefit from AlertZ In end */}

      {/* Desktop Section */}
      {/* Our Latest Blogs In start */}
      <section className="news-blogs blog-desktop-section" style={{ position: 'inherit', backgroundColor: '#f5fafe' }}>
        <div className="overlay pt-4 pb-60">
          <div className="container wow fadeInUp">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <div className="section-header text-center mb-4">
                  <h2 className="title" style={{ fontSize: '36px' }}>
                    Latest Chargeback News & Insights
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="feature-carousel">
                <Slider {...settings}>
                  {blogList.map((pair, index) => (
                    <div key={index}>
                      <div className="top-content text-start news-blogs-slider-top-contant row">
                        {pair.map((blog, index) => (
                          <div className="col-4" key={index}>
                            <div className="blog-item">
                              {/* <div className="thumb">
                                <img src={blog.imageUrl} alt="blog" />
                              </div> */}
                              <div className="content">
                                <Link to={`/blog/${blog.blogLink}`} className="pt-2">
                                  <h6 style={{ fontSize: '18px' }}>{blog.title}</h6>
                                  <br />
                                </Link>
                                <div className="info" style={{ display: 'block' }}>
                                  <div className="item d-flex align-items-center">
                                    <span className="icon d-flex align-items-center justify-content-center ">
                                      <img src="assets/img/icon/user.png" alt="icon" />
                                    </span>
                                    <span className="ps-2">Post By {blog.author}</span>
                                  </div>
                                  <div className="item d-flex align-items-center">
                                    <span className="icon d-flex align-items-center justify-content-center">
                                      <img src="assets/img/icon/calendar.png" alt="icon" />
                                    </span>
                                    <span className="ps-2">{formattedDate(blog.createdOn)}</span>
                                  </div>
                                </div>
                                <p>{blog.excerpt}</p>
                                <div className="read">
                                  <Link to={`/blog/${blog.blogLink}`} className="btn-arrow">
                                    Read more
                                    {/* <img src="assets/img/icon/arrow-right.png" alt="arrow" /> */}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Latest Blogs In end */}

      {/* Mobile Section */}
      {/* Our Latest Blogs In start */}
      <section className="more-feature blog-mobile-section" style={{ position: 'inherit', backgroundColor: '#f5fafe' }}>
        <div className="overlay pt-4 pb-60">
          <div className="container wow fadeInUp">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <div className="section-header text-center mb-4">
                  <h2 className="title" style={{ fontSize: '36px' }}>
                    Latest Chargeback News & Insights
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <Slider {...mobileSettings}>
                {mobilBlogList.map((blog, index) => (
                  <div key={index}>
                    <div className="top-content text-start news-blogs-slider-top-contant row p-1">
                      <div className="col-1"></div>
                      <div className="col-10">
                        <div className="blog-item">
                          {/* <div className="thumb">
                            <img src={blog.imageUrl} alt="blog" />
                          </div> */}
                          <div className="content">
                            <a href={`/blog/${blog.blogLink}`} className="pt-2">
                              <h6 style={{ fontSize: '18px' }}>{blog.title}</h6>
                              <br />
                            </a>
                            <div className="info" style={{ display: 'block' }}>
                              <div className="item d-flex align-items-center">
                                <span className="icon d-flex align-items-center justify-content-center ">
                                  <img src="assets/img/icon/user.png" alt="icon" />
                                </span>
                                <span className="ps-2">Post By {blog.author}</span>
                              </div>
                              <div className="item d-flex align-items-center">
                                <span className="icon d-flex align-items-center justify-content-center">
                                  <img src="assets/img/icon/calendar.png" alt="icon" />
                                </span>
                                <span className="ps-2">{formattedDate(blog.createdOn)}</span>
                              </div>
                            </div>
                            <p>{blog.excerpt}</p>
                            <div className="read">
                              <a href={`/blog/${blog.blogLink}`} className="btn-arrow">
                                Read more
                                {/* <img src="assets/img/icon/arrow-right.png" alt="arrow" /> */}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>

          </div>
        </div>
      </section>
      {/* Our Latest Blogs In end */}
      <section
        className="get-start wow fadeInUp pt-3 pb-120"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <div className="overlay">
          <div className="container">
            <div className="col-12">
              <div className="get-content">
                <div className="section-text">
                  <h3 className="title">Ready to Enroll Now?</h3>
                  <h5 className="text-light">No credit card is required</h5>
                  <p>
                    It only takes a few minutes to enroll now.
                  </p>
                </div>
                <button onClick={isMobile ? handleShowEnrollNowMobileModal : handleShowEnrollNowModal} className="cmn-btn" style={{ backgroundColor: '#2196F3', borderColor: '#2196F3' }}>
                  Enroll now
                </button>
                <img src="assets/img/get-start.png" alt="images" />
              </div>
            </div>
          </div>
        </div>
      </section>



      {(!isEnrollNowModal && !isEnrollNowMobileModal) && (
        <>
          <MobileEnrollNow onClick={isMobile ? handleShowEnrollNowMobileModal : handleShowEnrollNowModal} />
          <ProgressCircle />
        </>
      )}
      <EarlyAccessNowMobileModal
        isEnrollNowModal={isEnrollNowMobileModal}
        handleCloseEnrollNowModal={handleCloseEnrollNowMobileModal}
        isFormSubmited={isFormSubmited}
        setIsFormSubmited={setIsFormSubmited} />

      <EarlyAccessNowDesktopModal
        isEnrollNowModal={isEnrollNowModal}
        handleCloseEnrollNowModal={handleCloseEnrollNowModal}
        isFormSubmited={isFormSubmited}
        setIsFormSubmited={setIsFormSubmited} />
      <Footer />
    </>
  );
}
export default Home;
