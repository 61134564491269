import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProgressCircle from "../components/Progress/ProgressCircle";

const VisaCompellingEvidence = () => {
    return (
        <>
            <Header />

            {/* Blog Single In start */}
            <section className="blog-single pt-60">
                <div className="overlay pt-60 pb-1">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="blog-contant mb-60 text-center">
                                            <h2>
                                                Visa Compelling Evidence 3.0: A Weapon Against Friendly Fraud
                                            </h2>
                                            <div className="info d-flex justify-content-center align-items-center">
                                                {/* <div className="item cus-bor d-flex align-items-center">
                                                    <span className="xlr">Loan</span>
                                                </div> */}
                                                <div className="item d-flex align-items-center">
                                                    <span className="xlr">5 May 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="img-area-top">
                                    <img src="assets/img/blog-images/blog-single-img-1.png" alt="images" />
                                </div>
                                <div className="contant-top-area pt-5">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10">
                                            <div className="blog-contant mt-60">
                                                <div className="blog-text">

                                                    <p>
                                                        For online businesses, chargebacks are a constant thorn in the side.
                                                        Not only do they represent lost revenue, but they can also damage your
                                                        reputation with payment processors. Friendly fraud, where a cardholder
                                                        disputes a legitimate charge, is a particularly frustrating form of chargeback.
                                                    </p>
                                                    <p>
                                                        Thankfully, Visa has introduced Compelling Evidence 3.0 (CE 3.0) to help merchants
                                                        fight back. This update to Visa's dispute resolution process provides a clear
                                                        framework for using a cardholder's past purchase history as evidence of legitimacy
                                                        in a disputed transaction.

                                                    </p>
                                                </div>
                                                <div className="blog-text">
                                                    <h4>What is CE 3.0?</h4>
                                                    <p>
                                                        CE 3.0 essentially allows merchants to counter friendly fraud claims by demonstrating
                                                        a pattern of legitimate past transactions between the cardholder and your business.
                                                        By meeting specific criteria, you can significantly increase your chances of winning
                                                        a chargeback dispute with reason code 10.4 (card-absent fraud).
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="img-area-blog mb-60">
                                    <img src="assets/img/blog-images/blog-single-img-2.png" alt="images" />
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="blog-contant">
                                            <div className="single-item mb-60">
                                                <h4>How Does it Work?</h4>
                                                <p>
                                                    Here's the gist of CE 3.0:
                                                </p>
                                                <ul>
                                                    <li>
                                                        You can submit evidence of two prior non-fraudulent transactions between you and the cardholder.
                                                    </li>
                                                    <li>
                                                        These transactions must have occurred within 120 to 365 days of the disputed transaction.
                                                    </li>
                                                    <li>
                                                        At least one element (device ID, IP address, email address, or shipping address) must match across all three transactions.
                                                    </li>
                                                </ul>
                                                <p className="pt-3">
                                                    By providing this data, you establish a connection between the cardholder and your business,
                                                    making it more likely that the disputed transaction was legitimate.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="blog-contant">
                                            <div className="single-item">
                                                <h4>Benefits for Merchants</h4>
                                                <p>
                                                    CE 3.0 offers several advantages for online businesses:

                                                </p>
                                                <ul>
                                                    <li>
                                                        <b>Reduced Chargebacks:</b> By effectively combating friendly fraud, you can
                                                        see a significant decrease in illegitimate chargebacks.
                                                    </li>
                                                    <li>
                                                        <b>Improved Cash Flow:</b> Winning chargeback disputes means less lost revenue.
                                                    </li>
                                                    <li>
                                                        <b>Stronger Case for Disputes:</b> CE 3.0 provides a clear and standardized method for presenting evidence in your favor.
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="blog-contant">
                                            <div className="single-item pt-4">
                                                <h4>Getting Ready for CE 3.0</h4>
                                                <p className="pt-2">
                                                    To leverage CE 3.0 effectively, you'll need to:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <span className="fw-bold">Familiarize yourself with the criteria: </span> Ensure your team understands the specific requirements for submitting CE 3.0 evidence.
                                                    </li>
                                                    <li style={{ display: 'block' }}>
                                                        <span className="fw-bold">Work with your acquirer: </span> Collaborate with your payment processor to ensure they can collect and submit the necessary data during disputes.
                                                    </li>
                                                    <li>
                                                        <span className="fw-bold">Maintain good data practices: </span> Make sure you're securely storing customer transaction data to facilitate CE 3.0 submissions.
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div className="blog-contant pt-4">
                                            <div className="single-item">
                                                <p>
                                                    <b>CE 3.0 is a valuable tool for online businesses battling friendly fraud. By understanding its
                                                        guidelines and working with your acquirer,
                                                        you can strengthen your defense against illegitimate chargebacks and protect your revenue.</b>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Blog Single In end */}
            <ProgressCircle />
            <Footer />
        </>
    )
}
export default VisaCompellingEvidence