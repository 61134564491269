import axios from 'axios'
import { Get_Recent_Post_List, Get_Web_Blog_Details, Get_Web_Blog_List, Submit_Get_Early_Access } from '../config/APIUrls';

class WebsiteService {
    submitGetEarlyAccess = async (data) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        return await axios.post(Submit_Get_Early_Access, data, {
            headers: headers
        });
    }
    getWebBlogList = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        return await axios.get(Get_Web_Blog_List, {
            headers: headers
        });
    }
    getWebBlogDetails = async (blogLink) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        return await axios.get(`${Get_Web_Blog_Details}?blogLink=${blogLink}`, {
            headers: headers
        });
    }
    getRecentPostList = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }
        return await axios.get(Get_Recent_Post_List, {
            headers: headers
        });
    }
}
const WebsiteServiceInstance = new WebsiteService();

export default WebsiteServiceInstance;